import React, { Component } from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import configureStore from './store/index';
import Root from './components/root';

import 'ace-css/css/ace.min.css';
import './assets/styles/index.scss';
import './assets/styles/icomoon.scss';

export default class App extends Component {
  constructor() {
    super();
    this.state = {
      isLoading: true,
      store: null,
    };
  }

  componentWillMount() {
    let store = configureStore(() => {
      //console.log("store configured");
      this.setState({ isLoading: false });
    });
    this.setState({ store });
  }

  render() {
    if (!this.state.store) {
      return <div>Loading...</div>;
    }
    return (
      <Provider store={this.state.store}>
        <Root />
      </Provider>
    );
  }
}

render(<App />, document.getElementById('root'));

// serviceWorker.unregister();
// document.body.style.transformOrigin = "50% 0";
// document.body.style.transform = "scale(0.7)";
// document.body.style.zoom = "80%";
