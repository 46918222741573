import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import * as status_const from '../store/reducers/status';
import { updateStatus, addSummaryItem } from '../store/reducers/status';
import moment from 'moment';

import { getSummary } from '../api';

const SerachDoc = (props) => {
  const { dispatch, info } = props;
  const [result, setResult] = useState(null);
  const [id, setId] = useState('');
  const [loading, setLoading] = useState(false);

  const search = async (e, value) => {
    e && e.preventDefault();
    setLoading(true);
    if (!value) return null;
    const data = await getSummary(value);

    console.log('data', data);
    setResult(data);
    setLoading(false);
  };

  const handleChange = (e) => {
    setId(e.target.value);
  };

  const loadSummary = (data) => {
    // console.log('LOAD SUMMARY DATA', data);
    const { summary, total, info, discount } = data;
    if (discount) {
      dispatch(updateStatus(status_const.SET_DISCOUNT, { discount }));
    }
    if (info) {
      dispatch(updateStatus(status_const.SET_INFO, info));
    }
    dispatch(addSummaryItem({ summary, total }));
    props.onCancel();
  };

  const showResult = () => {
    const { data, createdAt } = result;
    const { total, isInvoice } = data;
    const date = moment(createdAt).format('DD-MM-YYYY HH:mm:ss');
    return (
      <div
        className={'mb1 btn-simple'}
        key={result.id}
        onClick={() => loadSummary(data)}
      >
        <div className="full-w flex flex-column p1">
          <div className="flex justify-between">
            <span>{`#${result.id}`}</span>

            <span>{`${isInvoice ? 'Conto' : 'Preventivo'}`}</span>
          </div>
          <div className="flex justify-between">
            <span>
              <strong>Importo:</strong> {`${total.toFixed(2)} €`}
            </span>
            <span>
              <strong>Data:</strong> {date}
            </span>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="flex flex-column flex-center full-w m3">
      <form onSubmit={(e) => search(e, id)}>
        <div className="flex justify-between items-center full-w mb1">
          <div className="full-w mr1">
            <label className="label">
              <FormattedMessage id="actions.id" />
            </label>
            <input
              className="input"
              type="number"
              value={id}
              placeholder="number"
              onChange={(e) => handleChange(e)}
            />
          </div>
          <button className={'btn btn-small'} type="submit">
            <FormattedMessage id="actions.search" />
          </button>
        </div>
      </form>

      <div className="full-w m1">
        {loading && <FormattedMessage id="actions.loading" />}

        {!result && <FormattedMessage id="labels.no_results" />}
      </div>

      {result && showResult()}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    info: state.status.info,
  };
}

export default connect(mapStateToProps)(SerachDoc);
