import React, { Component } from 'react';
import { connect } from 'react-redux';
import { switchPlace } from '../store/reducers/ui';
import classNames from 'classnames';

class ChoosePlace extends Component {
  render() {
    let { places } = this.props;
    return (
      <div className="flex flex-column flex-center full-w m3">
        {places &&
          places.map((place) => {
            let checked = false;
            if (this.props.place) checked = place.id === this.props.place.id;

            var btnClass = classNames({
              'btn m1 ': true,
              'btn-selected': checked,
              'btn-block': true,
            });
            return (
              <button
                className={btnClass}
                key={place.id}
                onClick={() => this.props.switchPlace(place)}
              >
                <div className="btn-block" style={{ textAlign: 'left' }}>
                  <span>{place.nick}</span>
                  <span className="ml1">
                    {checked && <span className="icon-normal icon-ok-black" />}
                  </span>
                </div>
              </button>
            );
          })}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    place: state.ui.place,
    places: state.srv.places,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    switchPlace: (place) => dispatch(switchPlace(place)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(ChoosePlace);
