import { produce } from 'immer';
import _ from 'lodash';

export const SET_STEP = 'SET_STEP';
export const SET_LEVEL = 'SET_LEVEL';
export const SET_CATS = 'SET_CATS';
export const SET_CURRENT = 'SET_CURRENT';
export const SET_SUMMARY = 'SET_SUMMARY';
export const RESET = 'RESET';
export const UPDATE_SUMMARY = 'UPDATE_SUMMARY';
export const SET_INVOICE = 'SET_INVOICE';
export const SET_INFO = 'SET_INFO';
export const SET_DISCOUNT = 'SET_DISCOUNT';

export const CAT = 'category';
export const SRV = 'service';

export const SET_QUANTITY = 'SET_QUANTITY';
export const SET_CUSTOM_PU = 'SET_CUSTOM_PU';
export const SET_CUSTOM_TOT = 'SET_CUSTOM_TOT';
export const SET_TOTAL_DISCOUNT = 'SET_TOTAL_DISCOUNT';
export const ADD_SUMMARY_ITEM = 'ADD_SUMMARY_ITEM';
export const EDIT_SUMMARY_ITEM = 'EDIT_SUMMARY_ITEM';

export const updateStatus = (type, payload) => ({ type, payload });
export const updateSummary = (payload) => ({ type: SET_SUMMARY, payload });

export const setQuantity = (payload) => ({ type: SET_CURRENT, payload });
export const customPrice = (payload) => ({ type: SET_CURRENT, payload });
export const customTotal = (payload) => ({ type: SET_CURRENT, payload });
export const totalDiscount = (payload) => ({
  type: SET_TOTAL_DISCOUNT,
  payload,
});

export const addSummaryItem = (payload) => ({
  type: ADD_SUMMARY_ITEM,
  payload,
});
export const setStep = (payload) => ({
  type: SET_STEP,
  payload,
});
export const removeSummaryItem = (payload) => ({
  type: UPDATE_SUMMARY,
  payload,
});

const initialState = {
  current_item: null,
  selected_categories: [{}],
  level: -1,
  summary: [],

  invoice: null,
  info: null,
  total: 0,
  discount: 0,
  current_related: null,
};

const rootReducer = produce((draft, action) => {
  let { payload } = action;

  // console.log("ACTION = ", action);
  // console.log("PAYLOAD = ", payload);

  switch (action.type) {
    case ADD_SUMMARY_ITEM:
      draft.summary = payload.summary;
      draft.total = payload.total;

      draft.current_item = null;
      draft.selected_categories = [{}];
      draft.level = -1;
      break;

    case SET_STEP:
      _.keys(payload).map((k) => {
        draft[k] = payload[k];
        return k;
      });
      break;

    case RESET:
      draft.current_item = null;
      draft.selected_categories = [{}];
      draft.level = -1;
      draft.summary = [];
      draft.invoice = null;
      draft.info = null;
      draft.total = 0;
      draft.discount = 0;
      draft.current_related = null;

      break;
    case SET_LEVEL:
      draft.level = payload;
      break;
    case SET_INFO:
      draft.info = payload;
      break;
    case SET_CATS:
      draft.selected_categories = payload;
      break;
    case SET_CURRENT:
      draft.current_item = payload;
      break;

    case UPDATE_SUMMARY:
      draft.summary = payload.summary;
      draft.total = payload.total;
      break;

    case SET_DISCOUNT:
      draft.discount = payload.discount;
      break;

    case SET_SUMMARY:
      draft.summary = payload;
      draft.current_item = null;
      draft.selected_categories = [{}];
      draft.level = -1;
      break;

    case SET_INVOICE:
      draft.invoice = payload;
      break;

    default:
      break;
  }
}, initialState);

export default rootReducer;
