import { query, queryGroups, queryPlaces } from './queries.js';
import { genRandomColor } from '../utils/utils';
import { loadState, removeState } from '../utils/storage';
//QUERY GRAPHQL DATO
const {
  REACT_APP_DATO_API_KEY: DATO_API_KEY,
  REACT_APP_DATO_ENDPOINT_URL: DATO_ENDPOINT_URL,
  REACT_APP_ENDPOINT_URL: ENDPOINT_URL,
} = process.env;

//JWT HASH
let hash = null;
function getHash() {
  try {
    // console.log("get hash", hash);
    // if (hash) return hash;

    const persistedState = loadState();
    if (persistedState) {
      // console.log("PERSISTED-STATE", persistedState);
      const { ui } = persistedState;
      const { auth } = ui;
      if (auth) {
        hash = auth.hash;
        // console.log("load hash", hash);
      }
      return hash;
    }
    return null;
  } catch (err) {
    console.error('getHash Error', err);
    return null;
  }
}

const doQuery = async (q) => {
  try {
    return await fetch(DATO_ENDPOINT_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${DATO_API_KEY}`,
      },
      body: JSON.stringify({ query: q }),
    }).then((res) => res.json());
  } catch (error) {
    console.log('QUERY ERROR', error, 'on query', q);
    throw error;
  }
};

//GET TREE
export async function init() {
  console.log('init');
  const response = await doQuery(query);
  return response.data;
}

//GET GROUPS
export async function getGroups() {
  const response = await doQuery(queryGroups);
  const groups = response.data.groups
    .filter((g) => g.group != null)
    .map((g) => {
      g.color = genRandomColor();
      return g;
    });
  return groups;
}

//GET PLACES
export async function getPlaces() {
  const response = await doQuery(queryPlaces);
  return response.data.allPlaces;
}

function isAuthorized(response) {
  if (response.status === 401 || response.status === 403) {
    removeState();
    window.document.location.reload();
    return false;
  } else {
    return true;
  }
}

//API SERVER
//POST
async function post(path, payload) {
  console.log('POST', path, payload);
  let opt = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  };

  const jwt = getHash();
  if (jwt) {
    opt.headers['Authorization'] = `Bearer ${jwt}`;
  }

  const response = await fetch(`${ENDPOINT_URL}/${path}`, opt);
  const json = await response.json();

  if (!isAuthorized(response)) {
    return null;
  }

  return json;
}
//PUT
async function put(path, payload) {
  console.log('PUT', path);
  try {
    let opt = {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    };

    const jwt = getHash();
    if (jwt) {
      opt.headers['Authorization'] = `Bearer ${jwt}`;
    }

    const response = await fetch(`${ENDPOINT_URL}/${path}`, opt);
    const json = await response.json();

    if (!isAuthorized(response)) {
      return null;
    }
    return json;
  } catch (err) {
    alert('ERROR: ' + JSON.stringify(err));
    return null;
  }
}

//GET
async function get(path) {
  console.log('GET', path);
  let opt = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };

  const jwt = getHash();
  if (jwt) {
    opt.headers['Authorization'] = `Bearer ${jwt}`;
  }

  const response = await fetch(`${ENDPOINT_URL}/${path}`, opt);
  const json = await response.json();

  if (!isAuthorized(response)) {
    return null;
  }
  return json;
}

/*
//DELETE
async function del(path) {
  console.log("DELETE", path);
  try {
    let opt = {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    };
    let json = await fetch(`${ENDPOINT_URL}/${path}`, opt)
      .then(response => response.json())
      .catch(e => {
        console.error("GET ERROR", e);
        throw e;
      });
    return json;
  } catch (err) {
    console.error("GET ERROR2", err);
    return null;
  }
}

//QUERY STRING
  function queryParams(params) {
  return Object.keys(params)
    .map(k => encodeURIComponent(k) + "=" + encodeURIComponent(params[k]))
    .join("&");
}

// BASE URL PDF
  function getPrintUrl(path, params) {
  console.log("get print url");
  let qs = "";
  if (params) {
    qs = "?" + queryParams(params);
  }
  let url = `${ENDPOINT_URL}/${path}${qs}`;
  return Promise.resolve(url);
}
*/

//LOGIN

export function ping() {
  console.log('PING');
  return get(`ping`);
}

export function login(opt) {
  return post('login', opt);
}

export function sendEmail(id) {
  return get(`summaries/mail/${id}`);
}

export async function sendToServer(data, type, recordId) {
  console.log('sendToServer', recordId, type, data);

  let result = null;
  if (recordId) {
    console.log('UPDATE', recordId);
    result = await put(`summaries/${recordId}`, data);
  } else {
    console.log('CREATE');
    result = await post('summaries', data);
  }
  const { id } = result;
  let url = null;

  if (type === 'mail' && (id || recordId)) {
    const mailId = id ? id : recordId;

    console.log('MAIL ID', mailId);
    await sendEmail(mailId);
  }
  if (type === 'print') {
    url = `${ENDPOINT_URL}/summaries/print/${id}`;
  }

  return { id, url };
}

export function sendToScloby(id) {
  return post(`summaries/scloby/${id}`);
}

export function getSummary(id) {
  return get(`summaries/${id}`);
}

//LAST

export function lastSummaries() {
  return get(`summaries/last`);
}

export function lastUsers() {
  return get(`users/last`);
}

export function lastProducts(store) {
  console.log(' store', store);
  return get(`products/last/${store}`);
}

//SEARCH

export function searchSummaryByUser(email) {
  return get(`summaries/find/${email}`);
}

export function searchUser(email) {
  console.log('search user find', email);
  return get(`users/find/${email}`);
}

export function searchUserByName(name) {
  console.log('users/findByName?name=', name);
  return get(`users/findByName?name=${name}`);
}

export function searchProductByCode(code, store) {
  return get(`products/find?store=${store}&code=${code}`);
}

export function searchProduct(name, store) {
  console.log('name, store', name, store);
  return get(`products/findByName?store=${store}&name=${name}`);
}

//MOVEMENT

export async function productMovement(data) {
  console.log('product movement', data);
  const result = await post('movements', data);
  console.log('result', result);
  return result;
}
