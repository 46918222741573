import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import usePortal from 'react-useportal';
import { connect } from 'react-redux';

import { switchLocale, switchPlace, logout } from '../store/reducers/ui';
import { clearCache } from '../store/reducers/srv';
import { removeCache } from '../utils/storage';
import * as status_const from '../store/reducers/status';
import { updateStatus } from '../store/reducers/status';
import * as modal_constants from '../utils/modal_constants';
import logo from '../assets/images/logo.png';

import MegaSearch from './mega_search';

import {
  FiSearch,
  FiDownload,
  FiEdit,
  FiUser,
  FiFilter,
  FiFileText,
  FiUsers,
  FiUserCheck,
  FiUserPlus,
  FiBox,
  FiCopy,
  FiExternalLink,
  FiMapPin,
  FiMaximize,
} from 'react-icons/fi';

function Header(props) {
  function render_lang_choose() {
    return (
      <div className="action">
        <button onClick={() => props.switchLocale('en')}>
          <span>en</span>
        </button>

        <button onClick={() => props.switchLocale('it')}>
          <span>it</span>
        </button>
      </div>
    );
  }

  function reset_all() {
    props.updateStatus(status_const.RESET, null);
  }

  function openTab() {
    window.open('#', '_blank');
  }

  function doLogout() {
    props.logout();
    removeCache();
    props.clearCache();
  }

  function showPlace() {
    const { place } = props;
    return (
      <div>
        {!!place && (
          <div className="mr1 flex items-center justify-center ">
            <span
              onClick={() =>
                props.showModal(modal_constants.MODAL_CHOOSE_PLACE)
              }
            >
              {place.nick ? place.nick : place.title}
            </span>
            <span className="ml1">
              <button
                className="btn-link"
                onClick={() =>
                  props.showModal(modal_constants.MODAL_CHOOSE_PLACE)
                }
              >
                <FiMapPin className="react_icon" />
              </button>
            </span>
          </div>
        )}
        {!place && (
          <button
            className="mr1 btn-link"
            onClick={() => props.showModal(modal_constants.MODAL_CHOOSE_PLACE)}
          >
            <FormattedMessage id="dialogs.choose_place" />
          </button>
        )}
      </div>
    );
  }

  function showInfo() {
    const { info } = props;
    return (
      <div>
        {!!info && (
          <div className="mr1 flex items-center justify-center ">
            <span
              onClick={() => props.showModal(modal_constants.MODAL_SET_INFO)}
            >
              {info.title}
            </span>
            <span className="ml1">
              <button
                className="btn-link"
                onClick={() => props.showModal(modal_constants.MODAL_SET_INFO)}
              >
                <FiUserCheck className="react_icon" />
              </button>
            </span>
          </div>
        )}
        {!info && (
          <button
            className="mr1 btn-link"
            onClick={() => props.showModal(modal_constants.MODAL_SET_INFO)}
          >
            <FiUserPlus className="react_icon" />
          </button>
        )}
      </div>
    );
  }

  const { openPortal, closePortal, isOpen, Portal } = usePortal();

  return (
    <Fragment>
      <div className="header">
        <div className="flex items-center">
          <img className="logo" src={logo} alt={'Copisteria Universale'} />
          <button className="mr1 btn-link" onClick={() => doLogout()}>
            Logout
          </button>
        </div>

        <div className="flex items-center">
          {showPlace()}

          {showInfo()}

          <button className="mr1 btn-link" onClick={openPortal}>
            <FiSearch className="react_icon" alt="Cerca" />
          </button>

          <button onClick={() => openTab()} className="btn-oval mr1">
            <span className="icon-medium icon-plus-black-alone" />
          </button>
        </div>
      </div>
      {isOpen && (
        <Portal>
          <MegaSearch closePortal={closePortal} />
        </Portal>
      )}
    </Fragment>
  );
}

function mapStateToProps(state) {
  return {
    locale: state.ui.locale,
    place: state.ui.place,
    places: state.srv.places,
    info: state.status.info,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    switchLocale: (locale) => dispatch(switchLocale(locale)),
    switchPlace: (place) => dispatch(switchPlace(place)),
    updateStatus: (type, data) => dispatch(updateStatus(type, data)),
    logout: () => dispatch(logout()),
    clearCache: () => dispatch(clearCache()),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Header);
