export const SWITCH_LOCALE = 'SWITCH_LOCALE';
export const SWITCH_PLACE = 'SWITCH_PLACE';
export const AUTH_USER = 'AUTH_USER';
export const LOGOUT = 'LOGOUT';

export function logout() {
  return {
    type: LOGOUT,
  };
}

export function switchLocale(locale) {
  return {
    type: SWITCH_LOCALE,
    locale,
  };
}

export function switchPlace(place) {
  return {
    type: SWITCH_PLACE,
    place,
  };
}

export function authorize(auth) {
  return {
    type: AUTH_USER,
    auth,
  };
}

const initialState = {
  locale: 'it',
  place: null,
  auth: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOGOUT:
      return {
        ...state,
        auth: null,
      };
    case AUTH_USER:
      return {
        ...state,
        auth: action.auth,
      };
    case SWITCH_LOCALE:
      return {
        ...state,
        locale: action.locale,
      };
    case SWITCH_PLACE:
      return {
        ...state,
        place: action.place,
      };

    default:
      return state;
  }
};
